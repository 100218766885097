import { useMutation } from "react-query";
import { toast } from "react-toastify";
import api from "../app/api";
import useUrlQuery from "../hooks/useUrlQuery";

import { useGetProposalDetailsQuery } from "./ProposalServices";
export const useSendQuoteMutation = () => {
  const { getUrlQuery } = useUrlQuery();
  const quote_id = getUrlQuery("quote_id");
  const { mutate } = useMutation(
    (data: any) => {
      const quoteData = {
        SECTION: "Bike",
        QUOTE_ID: quote_id,
        FIRST_NAME: data.first_name,
        LAST_NAME: data.last_name,
        TO: data.type === "MAIL" ? data.email : data.mobile_no,
        QUOTE_URI: window.location.href,
        QUOTES: data.quotes.map((item: any) => ({
          LOGO: item?.product_data?.LOGO_PATH,
          SLUG: item?.product_data?.INSURANCE_SLUG,
          PREMIUM: item?.premium_data?.PREMIUM_DATA?.TOTAL_PREMIUM,
        })),
      };
      return api.post(
        data.type === "MAIL" ? "/EmailQuote" : "/SMSQuote",
        quoteData
      );
    },
    {
      onSuccess: () => {
        const shareButton = document.querySelectorAll("button.shareButton");
        shareButton[0].classList.add("sent");
        toast.success("Email sent !!", {
          position: "top-right",
        });
      },
    }
  );

  return {
    sendQuoteEmail: mutate,
  };
};
export const useSendProposalMutation = () => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const { proposal_data, product_data } = useGetProposalDetailsQuery();
  const { mutate } = useMutation(
    (data: any) => {
      const proposalData = {
        SECTION: "Bike",
        PROPOSAL_ID: proposal_id,
        FIRST_NAME: data.first_name,
        LAST_NAME: data.last_name,
        TO: data.type === "MAIL" ? data.email : data.mobile_no,
        PROPOSAL_URI: window.location.href,
        PREMIUM_AMOUNT: proposal_data?.TOTAL_PREMIUM,
        COMPANY_NAME: product_data?.INSURANCE_NAME,
      };
      return api.post(
        data.type === "MAIL" ? "/EmailProposal" : "/SMSProposal",
        proposalData
      );
    },
    {
      onSuccess: () => {
        const shareButton = document.querySelectorAll("button.shareButton");
        shareButton[0].classList.add("sent");
        toast.success("Email sent !!", {
          position: "top-right",
        });
      },
    }
  );

  return {
    sendProposalEmail: mutate,
  };
};
export const useSendPaymentMutation = () => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const { proposal_data, product_data } = useGetProposalDetailsQuery();
  const { mutate } = useMutation(
    (data: any) => {
      const proposalData = {
        SECTION: "Bike",
        PROPOSAL_ID: proposal_id,
        FIRST_NAME: data.first_name,
        LAST_NAME: data.last_name,
        TO: data.type === "MAIL" ? data.email : data.mobile_no,
        PAYMENT_URI: window.location.href,
        PREMIUM_AMOUNT: proposal_data?.TOTAL_PREMIUM,
        COMPANY_NAME: product_data?.INSURANCE_NAME,
      };
      return api.post(
        data.type === "MAIL" ? "/EmailPayment" : "/SMSPayment",
        proposalData
      );
    },
    {
      onSuccess: () => {
        const shareButton = document.querySelectorAll("button.shareButton");
        shareButton[0].classList.add("sent");
        toast.success("Email sent !!", {
          position: "top-right",
        });
      },
    }
  );

  return {
    sendPaymentEmail: mutate,
  };
};

export const useSubmitQRLeadsMutation = () => {
  const { mutate, mutateAsync } = useMutation((data: any) => {
    return api.post("/SubmitQRLeads", data);
  });

  return {
    submitQRLeads: mutate,
    submitQRLeadsAsync: mutateAsync,
  };
};
